import React from 'react';

import { Button, Props as ButtonProps } from '../Button';

type Props = ButtonProps

export type ButtonLinkProps = Props;

export const ButtonLink = ({
  children,
  href,
  ...rest
}: Props) => (
  <Button
    href={href}
    {...rest}
  >
    {children}
  </Button>
);
