import React from 'react';
import { Flex } from '../../../../wag-react/next-components/Flex';
import { H2 } from '../../../../wag-react/core-components/h2';

import { BodyContainer } from '../BodyContainer';

import HomePageReviewCarousel from './components/HomePageReviewCarousel/HomePageReviewCarousel';

export const ReviewsBlock = () => (
  <Flex
    width={1}
    flexDirection="column"
    alignItems="strech"
  >
    <Flex
      bg="backgroundGray"
      width={1}
      color="white"
      justifyContent="center"
      px={[0, 0, 2, 4]}
      pb={4}
      flexDirection="column"
      boxSizing="border-box"
      alignItems="center"
    >
      <BodyContainer
        flexDirection="column"
        maxWidth={1280}
      >
        <H2
          fontSize={['24px', '30px', '48px']}
          fontFamily="muli"
          lineHeight={2}
          maxWidth={['250px', '100%']}
          color="medGray"
          mb={[4, 5]}
          textAlign="center"
        >
          Millions of 5-star services and counting
        </H2>
        <HomePageReviewCarousel />
      </BodyContainer>
    </Flex>
  </Flex>
);
