import React from 'react';
import Swiper from 'react-id-swiper';
import { createGlobalStyle } from 'styled-components';

import { Box } from '../../../../../wag-react/next-components/box';
import { LazyImage } from '../../../../../wag-react/components/lazyload';
import { Typography } from '../../../../../wag-react/components/Typography';
import { Button } from '../../../../../wag-react/next-components/button';

import 'swiper/swiper-bundle.css';

import { Divider } from '../../../../../wag-react/core-components/divider';
import { services, type Service } from '../../../data/homepage.data';
import { theme as Theme } from '../../../../../wag-react/themes/base-theme';

type Props = {
  selected: Service;
  onClick?: (service: Service) => void;
};

const swiperParams = {
  breakpoints: {
    // https://github.com/facebook/flow/issues/380
    // eslint-disable-next-line no-useless-computed-key
    [320]: {
      slidesPerView: 4,
      spaceBetween: 20,
      slidesOffsetBefore: 5,
      slidesOffsetAfter: 5,
    },
    // eslint-disable-next-line no-useless-computed-key
    [768]: {
      slidesPerView: 5,
      spaceBetween: 8,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      allowSlidePrev: false,
      allowSlideNext: false,
    },
    // eslint-disable-next-line no-useless-computed-key
    [1120]: {
      slidesPerView: 5,
      spaceBetween: 30,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      allowSlidePrev: false,
      allowSlideNext: false,
      allowTouchMove: false,
      noSwiping: false,
    },
  },
};

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
    .swiper-container {
      background: #F2F2F2;
      // Needs to add "border-radius" here because it has a higher density to its container
      border-radius: 8px;
      z-index: 0;
    }
    @media screen and (max-width: ${theme.breakpoints[0]}) {
      .swiper-container {
        background: ${theme.colors.white};    
      }
    }
  `}
`;

export const WagServicesSlider = ({
  selected,
  onClick ,
}: Props) => (
  <Box>
    <GlobalStyle theme={Theme} />
    <Swiper {...swiperParams}>
      {services.map((service) => {
        const {
          key,
          name,
          iconURL,
        } = service;

        return (
          <Box
            display="flex"
            boxSizing="border-box"
            flexDirection="column"
            alignItems="center"
            pt={3}
            key={key}
            data-testid={key}
            minHeight={['132px', '144px', '148px']}
            onClick={() => onClick(service)}
          >
            <Box>
              <LazyImage
                height={['48px', '64px', '80px']}
                width={['48px', '64px', '80px']}
                src={iconURL}
                alt={`${name}-icon`}
              />
            </Box>
            <Box
              width={['90px', '90px', '128px']}
              borderBottom={selected.key === key ? '4px solid' : ''}
              borderColor="wagGreen"
            >
              <Typography
                color={selected.key === key ? 'wagGreen' : 'medGray'}
                fontSize={['14px', '14px', '18px']}
                textAlign="center"
                fontFamily="muli"
                fontWeight="bold"
                mb="16px"
                mt="8px"
                hover
              >
                {name}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Swiper>
    <Divider
      display={['block', 'none']}
      mt={0}
      mb={0}
    />
    <Box
      display="grid"
      gridTemplateColumns={['1fr', null, '2fr 1fr']}
      gridGap={['0px', '30px']}
      justifyItems={['center']}
      px={[3, 4, 5]}
      py="24px"
      minHeight={['280px', null, '200px']}
    >
      <Typography
        fontFamily="muli"
        fontSize={['16px', '20px']}
        color="medGray"
        lineHeight={['28px', '30px']}
        mt={1}
      >
        {selected.description.body}
        &nbsp;
        {selected.learnMore && (
          <Button
            href={selected.learnMore.url}
            fontFamily="muli"
            fontWeight="bold"
            fontSize={['16px', '20px']}
            lineHeight={['28px', '30px']}
            {...selected.learnMore.props}
          >
            {selected.learnMore.text}
          </Button>
        )}
      </Typography>
      <Box
        width={1}
      >
        {selected.firstTimePromotional ? (
          <Button
            variant="contained"
            fontSize={['16px', '20px']}
            width={['initial', null, 1]}
            href={selected.firstTimePromotional.url}
            {...selected.firstTimePromotional.props}
          >
            {selected.firstTimePromotional.text}
          </Button>
        ) : (
          <>
            {selected.firstTimePromotionalBlock}
          </>
        )}
      </Box>
    </Box>
  </Box>
);
