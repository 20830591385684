import React from 'react';

import { ThemeProvider } from 'styled-components';

import { BodyContainer } from '../BodyContainer';
import { Box } from '../../../../components/Box';
import { GlobalStyle as ThemeV2 } from '../../../../wag-react/themes/web-app-theme';
import { Typography } from '../../../../components/Typography';
import { CityList } from './components/CityList';

export const CitiesSection = () => (
  <ThemeProvider theme={ThemeV2}>
    <BodyContainer
      py={5}
      px={[3, 3, 0]}
    >
      <Typography
        variant="h2"
        color="medGray"
        m={0}
        textAlign={['left', 'left', 'center']}
        width={1}
      >
        Top Wag! cities
      </Typography>

      <Box
        mt={3}
        width={1}
      >
        <CityList />
      </Box>
    </BodyContainer>
  </ThemeProvider>
);
