// @flow
import pet1 from '@/assets/images/dogs/review-avatars/pet-1.png';
import pet2 from '@/assets/images/dogs/review-avatars/pet-2.png';
import pet3 from '@/assets/images/dogs/review-avatars/pet-3.png';
import pet4 from '@/assets/images/dogs/review-avatars/pet-4.png';
import pet5 from '@/assets/images/dogs/review-avatars/pet-5.png';
import pet6 from '@/assets/images/dogs/review-avatars/pet-6.png';

export default [
  {
    rating: 5,
    name: 'Curtis T',
    gender: 'F',
    review: 'The Wag! app is great for times I can\'t get home to walk my dog. The walkers leave a nice recap of my dog\'s behavior! Best of all, my dog loves meeting new people who come walk him!',
    src: pet1,
  },
  {
    rating: 5,
    name: 'Lisa M',
    gender: 'F',
    review: 'I couldn\'t seem to find the time to walk my dog, with a 9 month old baby and a night shift schedule. With Wag!, I get to meet and talk to my walker before, during, and after the walk and am able to get my dog walked right away!',
    src: pet2,
  },
  {
    rating: 5,
    name: 'Brenda G',
    gender: 'F',
    review: 'Wag! gives me great peace of mind while I am at work. I get to the minute potty information and a picture of his smile at the end of walk. Thank you Wag!!',
    src: pet3,
  },
  {
    rating: 5,
    name: 'Cristal L',
    gender: 'F',
    review: 'As a first responder I\'m gone for more than half the day. I rely on Wag! to walk my little guy as much as possible. My Wag! person is super cool and will even stay with my guys while I\'m away with all these fires and extended hours. Love you guys seriously!',
    src: pet4,
  },
  {
    rating: 5,
    name: 'Jenny Y',
    gender: 'F',
    review: 'Easy process, affordable prices, amazing walker!',
    src: pet5,
  },
  {
    rating: 5,
    name: 'Shannon J',
    gender: 'F',
    review: 'Love this app! So does my dog!!',
    src: pet6,
  },
];
