// @flow
import React from 'react';

import { Box } from '../../../../wag-react/next-components/box';
import { WagServicesSlider } from './components/WagServicesSlider';

type Props = {
  selected: any,
  onClick?: (service: any) => void;
};

export const WagServices = ({
  selected,
  onClick,
}: Props) => (
  <Box
    borderRadius={['0', 3]}
    boxShadow="0 2px 12px 0 rgba(0,0,0,0.2)"
    backgroundColor="white"
    mt={['auto', '-132px', '-152px']}
    width={['100%', '706px', '1120px']}
    mx="auto"
  >
    <Box>
      <WagServicesSlider
        selected={selected}
        onClick={onClick}
      />
    </Box>
  </Box>
);
