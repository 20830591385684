import React from 'react';

import styled, {
  keyframes,
} from 'styled-components';
import { Box, BoxProps } from '../../next-components/box';

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

type Props = BoxProps;
export type PlaceholderProps = Props;

export const Placeholder = (props: Props) => (
  <StyledBox
    bottom={0}
    height="100%"
    left={0}
    position="absolute"
    right={0}
    top={0}
    width="100%"
    {...props}
  />
);

const StyledBox = styled(Box)`
  animation: ${loadingAnimation} 1s infinite;
`;
