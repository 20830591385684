export const cities = [
  {
    city: 'Atlanta',
    stateAbbrev: 'GA',
    numCaregivers: '5053',
    slug: 'atlanta',
  },
  {
    city: 'Austin',
    stateAbbrev: 'TX',
    numCaregivers: '5511',
    slug: 'austin',
  },
  {
    city: 'Baltimore',
    stateAbbrev: 'MD',
    numCaregivers: '2232',
    slug: 'baltimore',
  },
  {
    city: 'Boston',
    stateAbbrev: 'MA',
    numCaregivers: '8099',
    slug: 'boston',
  },
  {
    city: 'Charlotte',
    stateAbbrev: 'NC',
    numCaregivers: '2496',
    slug: 'charlotte',
  },
  {
    city: 'Chicago',
    stateAbbrev: 'IL',
    numCaregivers: '13255',
    slug: 'chicago',
  },
  {
    city: 'Columbus',
    stateAbbrev: 'OH',
    numCaregivers: '2337',
    slug: 'columbus',
  },
  {
    city: 'Dallas',
    stateAbbrev: 'TX',
    numCaregivers: '4192',
    slug: 'dallas',
  },
  {
    city: 'Denver',
    stateAbbrev: 'CO',
    numCaregivers: '8250',
    slug: 'denver',
  },
  {
    city: 'Houston',
    stateAbbrev: 'TX',
    numCaregivers: '4557',
    slug: 'houston',
  },
  {
    city: 'Indianapolis',
    stateAbbrev: 'IN',
    numCaregivers: '1680',
    slug: 'indianapolis',
  },
  {
    city: 'Los Angeles',
    stateAbbrev: 'CA',
    numCaregivers: '16202',
    slug: 'los-angeles',
  },
  {
    city: 'Miami',
    stateAbbrev: 'FL',
    numCaregivers: '4221',
    slug: 'miami',
  },
  {
    city: 'Minneapolis',
    stateAbbrev: 'MN',
    numCaregivers: '2635',
    slug: 'minneapolis',
  },
  {
    city: 'Nashville',
    stateAbbrev: 'TN',
    numCaregivers: '2680',
    slug: 'nashville',
  },
  {
    city: 'New York',
    stateAbbrev: 'NY',
    numCaregivers: '22620',
    slug: 'new-york',
  },
  {
    city: 'Philadelphia',
    stateAbbrev: 'PA',
    numCaregivers: '5705',
    slug: 'philadelphia',
  },
  {
    city: 'Portland',
    stateAbbrev: 'OR',
    numCaregivers: '4114',
    slug: 'portland',
  },
  {
    city: 'Raleigh',
    stateAbbrev: 'NC',
    numCaregivers: '2238',
    slug: 'raleigh',
  },
  {
    city: 'San Diego',
    stateAbbrev: 'CA',
    numCaregivers: '6947',
    slug: 'san-diego',
  },
  {
    city: 'San Francisco',
    stateAbbrev: 'CA',
    numCaregivers: '11375',
    slug: 'san-francisco',
  },
  {
    city: 'Seattle',
    stateAbbrev: 'WA',
    numCaregivers: '6451',
    slug: 'seattle',
  },
  {
    city: 'Tampa',
    stateAbbrev: 'FL',
    numCaregivers: '2085',
    slug: 'tampa',
  },
  {
    city: 'Washington',
    stateAbbrev: 'DC',
    numCaregivers: '7680',
    slug: 'washington',
  },
];
