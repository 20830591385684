import { useContext } from 'react';

import { ThemeContext } from 'styled-components';

// ! TODO: extract to wag-react
export const useTheme = () => {
  const theme = useContext(ThemeContext);

  return { theme };
};
