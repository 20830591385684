export const statesData = [
  {
    state: 'Alabama',
    stateAbbrev: 'AL',
  },
  {
    state: 'Alaska',
    stateAbbrev: 'AK',
  },
  {
    state: 'Arizona',
    stateAbbrev: 'AZ',
  },
  {
    state: 'Arkansas',
    stateAbbrev: 'AR',
  },
  {
    state: 'California',
    stateAbbrev: 'CA',
  },
  {
    state: 'Colorado',
    stateAbbrev: 'CO',
  },
  {
    state: 'Connecticut',
    stateAbbrev: 'CT',
  },
  {
    state: 'Delaware',
    stateAbbrev: 'DE',
  },
  {
    state: 'Florida',
    stateAbbrev: 'FL',
  },
  {
    state: 'Georgia',
    stateAbbrev: 'GA',
  },
  {
    state: 'Hawaii',
    stateAbbrev: 'HI',
  },
  {
    state: 'Idaho',
    stateAbbrev: 'ID',
  },
  {
    state: 'Illinois',
    stateAbbrev: 'IL',
  },
  {
    state: 'Indiana',
    stateAbbrev: 'IN',
  },
  {
    state: 'Iowa',
    stateAbbrev: 'IA',
  },
  {
    state: 'Kansas',
    stateAbbrev: 'KS',
  },
  {
    state: 'Kentucky',
    stateAbbrev: 'KY',
  },
  {
    state: 'Louisiana',
    stateAbbrev: 'LA',
  },
  {
    state: 'Maine',
    stateAbbrev: 'ME',
  },
  {
    state: 'Maryland',
    stateAbbrev: 'MD',
  },
  {
    state: 'Massachusetts',
    stateAbbrev: 'MA',
  },
  {
    state: 'Michigan',
    stateAbbrev: 'MI',
  },
  {
    state: 'Minnesota',
    stateAbbrev: 'MN',
  },
  {
    state: 'Mississippi',
    stateAbbrev: 'MS',
  },
  {
    state: 'Missouri',
    stateAbbrev: 'MO',
  },
  {
    state: 'Montana',
    stateAbbrev: 'MT',
  },
  {
    state: 'Nebraska',
    stateAbbrev: 'NE',
  },
  {
    state: 'Nevada',
    stateAbbrev: 'NV',
  },
  {
    state: 'New Hampshire',
    stateAbbrev: 'NH',
  },
  {
    state: 'New Jersey',
    stateAbbrev: 'NJ',
  },
  {
    state: 'New Mexico',
    stateAbbrev: 'NM',
  },
  {
    state: 'New York',
    stateAbbrev: 'NY',
  },
  {
    state: 'North Carolina',
    stateAbbrev: 'NC',
  },
  {
    state: 'North Dakota',
    stateAbbrev: 'ND',
  },
  {
    state: 'Ohio',
    stateAbbrev: 'OH',
  },
  {
    state: 'Oklahoma',
    stateAbbrev: 'OK',
  },
  {
    state: 'Oregon',
    stateAbbrev: 'OR',
  },
  {
    state: 'Pennsylvania',
    stateAbbrev: 'PA',
  },
  {
    state: 'Rhode Island',
    stateAbbrev: 'RI',
  },
  {
    state: 'South Carolina',
    stateAbbrev: 'SC',
  },
  {
    state: 'South Dakota',
    stateAbbrev: 'SD',
  },
  {
    state: 'Tennessee',
    stateAbbrev: 'TN',
  },
  {
    state: 'Texas',
    stateAbbrev: 'TX',
  },
  {
    state: 'Utah',
    stateAbbrev: 'UT',
  },
  {
    state: 'Vermont',
    stateAbbrev: 'VT',
  },
  {
    state: 'Virginia',
    stateAbbrev: 'VA',
  },
  {
    state: 'Washington',
    stateAbbrev: 'WA',
  },
  {
    state: 'West Virginia',
    stateAbbrev: 'WV',
  },
  {
    state: 'Wisconsin',
    stateAbbrev: 'WI',
  },
  {
    state: 'Wyoming',
    stateAbbrev: 'WY',
  },
];
