// @flow
import React from 'react';

import { Box } from '../../../../wag-react/next-components/box';
import { Span } from '../../../../wag-react/core-components/span';
import { Button } from '../../../../wag-react/next-components/button';

import sectionBackgroundDesktop from './assets/animal-lovers-wanted-section-background-desktop.jpg';
import sectionBackgroundMobile from './assets/animal-lovers-wanted-section-background-mobile.jpg';
import sectionBackgroundTablet from './assets/animal-lovers-wanted-section-background-tablet.jpg';
import { URL_BECOME_WALKER } from '../../../../wag-react/components/site-navigation/urls';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../../../wag-react/themes/base-theme';
import { Typography } from '../../../../components/Typography';

export const AnimalLoversWantedSection = () => (
  <ThemeProvider theme={theme}>
    <Box
      backgroundImage={[
        `url(${sectionBackgroundMobile})`,
        `url(${sectionBackgroundTablet})`,
        `url(${sectionBackgroundDesktop})`,
      ]}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="top center"
      minHeight={['544px', '376px', '504px']}
    >
      <Box
        flexDirection={['column', 'row']}
        display="flex"
        justifyContent="flex-end"
        px={[3, 0, 0]}
      >
        <Box
          pt={['92%', '88px', '116px']}
          pb={['28px', '88px', '116px']}
          mx={['auto', 0]}
          pr={[0, 4, '160px']}
          width={[1, '472px', '744px']}
        >
          <Typography
            color="wagGreen"
            fontFamily="muli"
            variant="h3"
            fontSize={['24px', '30px', '48px']}
            mb={[3, 3, '32px']}
            mt={[0]}
            textAlign={['left', 'right']}
          >
          Animal lovers wanted!
          </Typography>
          <Typography
            color="medGray"
            fontFamily="muli"
            fontSize={['12px', '14px', '18px']}
            lineHeight={[0, 0, 1]}
            mb={[3, 3, '32px']}
            ml={['unset', 'auto']}
            mt={0}
            textAlign={['left', 'right']}
            width={['auto', '296px', '400px']}
          >
          Are you a dog lover with pet care experience?
            <br />
          Want to earn money working with dogs?
            <br />
          Learn more about becoming a dog walker, sitter, or trainer in your city.
          </Typography>
          <Box
            display="flex"
            justifyContent={['center', 'flex-end']}
          >
            <Button
              href={URL_BECOME_WALKER}
              variant="contained"
              px={3}
              py={[2, '12px', '16px']}
              width={['320px', '232px', '360px']}
            >
              <Span
                fontFamily="muli"
                fontSize={['14px', '16px', '21px']}
                fontWeight="bold"
                hover
              >
              Become a walker
              </Span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
);
