import React, {
  ReactNode,
} from 'react';

import {
  Box,
  Props as BoxProps,
} from '../Box';

export type Props = {
  children: ReactNode;
} & BoxProps;

export type ContainerProps = Props;

export const Container = ({
  children,
  ...rest
}: Props) => (
  <Box
    maxWidth={['100%', '688px', '1120px', '1600px']}
    mx="auto"
    px={['16px', '24px']}
    width={1}
    {...rest}
  >
    {children}
  </Box>
);
