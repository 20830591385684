import React, { useState } from 'react';
import styled from 'styled-components';
import Swiper, { ReactIdSwiperChildren } from 'react-id-swiper';

import { Flex } from '@/wag-react/core-components/flex';

import { CarouselNavigationButton } from '../CarouselNavigationButton';

const SwiperContainer = styled(Flex)`
  & .swiper-container {
    background-color: unset;
  }
`;

type Props = {
  swiperParams: any;
  children: ReactIdSwiperChildren;
  navigation?: {
    renderPrevButton;
    renderNextButton;
  },
};

export const Carousel = ({
  children,
  swiperParams,
  navigation = {
    renderPrevButton: () => { /* noop */ },
    renderNextButton: () => { /* noop */ },
  },
}: Props) => {
  const [swiper, setSwiper] = useState(null);
  const {
    containerProps,
  } = swiperParams;

  return (
    <>
      {navigation.renderPrevButton(swiper) || (
        <Flex
          zIndex={5}
          width={40}
          height={40}
          px={1}
        >
          {navigation.renderPrevButton(swiper) || (
            <CarouselNavigationButton
              onClick={() => {
                if (swiper !== null) {
                  swiper.slidePrev();
                }
              }}
              iconProps={{
                isInverted: true,
              }}
            />
          )}
        </Flex>
      )}
      <SwiperContainer
        {...containerProps}
      >
        <Swiper
          getSwiper={setSwiper}
          {...swiperParams}
        >
          {children}
        </Swiper>
      </SwiperContainer>
      {navigation.renderPrevButton(swiper) || (
        <Flex
          zIndex={5}
          width={40}
          height={40}
          px={1}
        >
          <CarouselNavigationButton
            onClick={() => {
              if (swiper !== null) {
                swiper.slideNext();
              }
            }}
          />
        </Flex>
      )}
    </>
  );
};
