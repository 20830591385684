export const QS_SIGNUP_PREFERRED_SERVICE = 'preferred_service';

export const QS_SIGNUP_FLOW_SOURCE = 'signup_flow_source';
export const QS_UTM_TERM = 'utm_term';
export const QS_UTM_CONTENT = 'utm_content';
export const QS_UTM_CAMPAIGN = 'utm_campaign';
export const QS_UTM_MEDIUM = 'utm_medium';
export const QS_UTM_SOURCE = 'utm_source';
export const QS_GCLID = 'gclid';
export const QS_AD_GROUP = 'ad_group';
export const QS_AD_CREATIVE = 'ad_creative';
export const QS_ADID = 'adid';

export const UtmCampaign = {
  WebFunnel: 'web-funnel',
} as const;

export const UtmSource = {
  Web: 'web',
} as const;

export const UtmMedium = {
  Organic: 'organic',
} as const;
