export const buildCols = (numCols: number, items: JSX.Element[]) => {
  const maxColLength = Math.ceil(items.length / numCols);
  const cols = [];

  for (let i = 0; i < items.length; i += maxColLength) {
    const currCol = [];
    for (let j = 0; j < maxColLength && j < items.length; j += 1) {
      if (i + j >= items.length) {
        break;
      }

      currCol.push(items[i + j]);
    }
    cols.push(currCol);
  }

  return cols;
};
