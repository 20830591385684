export const scrollToView = (
  selector: string,
  config: ScrollIntoViewOptions = {
    behavior: 'smooth',
  },
) => {
  document
    .querySelector(selector)
    .scrollIntoView(config);
};

export const scrollToViewById = (
  id: string,
  config: ScrollIntoViewOptions = {
    behavior: 'smooth',
  },
) => {
  document
    .getElementById(id)
    .scrollIntoView(config);
};

export const scrollToViewByCoords = ({
  top = 0,
  left = 0,
  behavior = 'smooth',
}: ScrollToOptions) => {
  window.scrollTo({ top, left, behavior });
};
