export const dogFoodCategories = [
  {
    title: 'Best Budget Friendly Dog Foods',
    slug: 'best-budget-friendly-dog-foods',
  },
  {
    title: 'Best Dog Food Delivery Service',
    slug: 'best-dog-food-delivery-service',
  },
  {
    title: 'Best Dog Food for Arthritis',
    slug: 'best-dog-food-for-arthritis',
  },
  {
    title: 'Best Dog Food for Australian Shepherds',
    slug: 'best-dog-food-for-australian-shepherds',
  },
  {
    title: 'Best Dog Food for Beagles',
    slug: 'best-dog-food-for-beagles',
  },
  {
    title: 'Best Dog Food for Bernese Mountain Dogs',
    slug: 'best-dog-food-for-bernese-mountain-dogs',
  },
  {
    title: 'Best Dog Food for Border Collies',
    slug: 'best-dog-food-for-border-collies',
  },
  {
    title: 'Best Dog Food for Boston Terriers',
    slug: 'best-dog-food-for-boston-terriers',
  },
  {
    title: 'Best Dog Food for Boxers',
    slug: 'best-dog-food-for-boxers',
  },
  {
    title: 'Best Dog Food for Bulldogs',
    slug: 'best-dog-food-for-bulldogs',
  },
  {
    title: 'Best Dog Food for Cane Corsos',
    slug: 'best-dog-food-for-cane-corsos',
  },
  {
    title: 'Best Dog Food for Canine Athletes',
    slug: 'best-dog-food-for-canine-athletes',
  },
  {
    title: 'Best Dog Food for Cavalier King Charles Spaniels',
    slug: 'best-dog-food-for-cavalier-king-charles-spaniels',
  },
  {
    title: 'Best Dog Food for Chihuahuas',
    slug: 'best-dog-food-for-chihuahuas',
  },
  {
    title: 'Best Dog Food for Cocker Spaniels',
    slug: 'best-dog-food-for-cocker-spaniels',
  },
  {
    title: 'Best Dog Food for Corgis',
    slug: 'best-dog-food-for-corgis',
  },
  {
    title: 'Best Dog Food for Dachshunds',
    slug: 'best-dog-food-for-dachshunds',
  },
  {
    title: 'Best Dog Food for Dalmatians',
    slug: 'best-dog-food-for-dalmatians',
  },
  {
    title: 'Best Dog Food for Diabetic Dogs',
    slug: 'best-dog-food-for-diabetic-dogs',
  },
  {
    title: 'Best Dog Food for Diarrhea',
    slug: 'best-dog-food-for-diarrhea',
  },
  {
    title: 'Best Dog Food for Doberman Pinschers',
    slug: 'best-dog-food-for-doberman-pinschers',
  },
  {
    title: 'Best Dog Food for Dogs with a Sensitive Stomach',
    slug: 'best-dog-food-for-dogs-with-a-sensitive-stomach',
  },
  {
    title: 'Best Dog Food for English Bulldogs',
    slug: 'best-dog-food-for-english-bulldogs',
  },
  {
    title: 'Best Dog Food for French Bulldogs',
    slug: 'best-dog-food-for-french-bulldogs',
  },
  {
    title: 'Best Dog Food for German Shepherds',
    slug: 'best-dog-food-for-german-shepherds',
  },
  {
    title: 'Best Dog Food for Golden Retrievers',
    slug: 'best-dog-food-for-golden-retrievers',
  },
  {
    title: 'Best Dog Food for Goldendoodles',
    slug: 'best-dog-food-for-goldendoodles',
  },
  {
    title: 'Best Dog Food for Great Danes',
    slug: 'best-dog-food-for-great-danes',
  },
  {
    title: 'Best Dog Food for Greyhounds',
    slug: 'best-dog-food-for-greyhounds',
  },
  {
    title: 'Best Dog Food for Havanese',
    slug: 'best-dog-food-for-havanese',
  },
  {
    title: 'Best Dog Food for Kidney Disease',
    slug: 'best-dog-food-for-kidney-disease',
  },
  {
    title: 'Best Dog Food for Labrador Retrievers',
    slug: 'best-dog-food-for-labrador-retrievers',
  },
  {
    title: 'Best Dog Food for Large Dogs',
    slug: 'best-dog-food-for-large-dogs',
  },
  {
    title: 'Best Dog Food for Maltese',
    slug: 'best-dog-food-for-maltese',
  },
  {
    title: 'Best Dog Food for Mastiffs',
    slug: 'best-dog-food-for-mastiffs',
  },
  {
    title: 'Best Dog Food for Miniature Schnauzers',
    slug: 'best-dog-food-for-miniature-schnauzers',
  },
  {
    title: 'Best Dog Food for Pancreatitis',
    slug: 'best-dog-food-for-pancreatitis',
  },
  {
    title: 'Best Dog Food for Picky Eaters',
    slug: 'best-dog-food-for-picky-eaters',
  },
  {
    title: 'Best Dog Food for Pit Bulls',
    slug: 'best-dog-food-for-pit-bulls',
  },
  {
    title: 'Best Dog Food for Pomeranians',
    slug: 'best-dog-food-for-pomeranians',
  },
  {
    title: 'Best Dog Food for Poodles',
    slug: 'best-dog-food-for-poodles',
  },
  {
    title: 'Best Dog Food for Pregnant Dogs',
    slug: 'best-dog-food-for-pregnant-dogs',
  },
  {
    title: 'Best Dog Food for Pugs',
    slug: 'best-dog-food-for-pugs',
  },
  {
    title: 'Best Dog Food for Puppies',
    slug: 'best-dog-food-for-puppies',
  },
  {
    title: 'Best Dog Food for Rottweilers',
    slug: 'best-dog-food-for-rottweilers',
  },
  {
    title: 'Best Dog Food for Shih Tzus',
    slug: 'best-dog-food-for-shih-tzus',
  },
  {
    title: 'Best Dog Food for Siberian Huskies',
    slug: 'best-dog-food-for-siberian-huskies',
  },
  {
    title: 'Best Dog Food for Small Dogs',
    slug: 'best-dog-food-for-small-dogs',
  },
  {
    title: 'Best Dog Food for Weight Gain',
    slug: 'best-dog-food-for-weight-gain',
  },
  {
    title: 'Best Dog Food for Working Breeds',
    slug: 'best-dog-food-for-working-breeds',
  },
  {
    title: 'Best Dog Food for Yorkshire Terriers',
    slug: 'best-dog-food-for-yorkshire-terriers',
  },
  {
    title: 'Best Dog Food Without Chicken',
    slug: 'best-dog-food-without-chicken',
  },
  {
    title: 'Best Dog Foods for Overweight Dogs',
    slug: 'best-dog-foods-for-overweight-dogs',
  },
  {
    title: 'Best Dog Foods for Senior Dogs',
    slug: 'best-dog-foods-for-senior-dogs',
  },
  {
    title: 'Best Dry Cat Food',
    slug: 'best-dry-cat-food',
  },
  {
    title: 'Best Dry Foods for Dogs',
    slug: 'best-dry-foods-for-dogs',
  },
  {
    title: 'Best Dry Puppy Food',
    slug: 'best-dry-puppy-food',
  },
  {
    title: 'Best Freeze-Dried Dog Food',
    slug: 'best-freeze-dried-dog-food',
  },
  {
    title: 'Best Fresh Dog Food Brands',
    slug: 'best-fresh-dog-food-brands',
  },
  {
    title: 'Best Frozen Dog Food',
    slug: 'best-frozen-dog-food',
  },
  {
    title: 'Best Grain Free Dog Food',
    slug: 'best-grain-free-dog-food',
  },
  {
    title: 'Best High Protein Dog Food',
    slug: 'best-high-protein-dog-food',
  },
  {
    title: 'Best High Protein Puppy Food',
    slug: 'best-high-protein-puppy-food',
  },
  {
    title: 'Best Hypoallergenic Dog Foods',
    slug: 'best-hypoallergenic-dog-foods',
  },
  {
    title: 'Best Joint Supplements for Dogs',
    slug: 'best-joint-supplements-for-dogs',
  },
  {
    title: 'Best Low Fat Dog Food',
    slug: 'best-low-fat-dog-food',
  },
  {
    title: 'Best Low Protein Dog Food',
    slug: 'best-low-protein-dog-food',
  },
  {
    title: 'Best Meal Toppers for Dogs',
    slug: 'best-meal-toppers-for-dogs',
  },
  {
    title: 'Best Organic Dog Food',
    slug: 'best-organic-dog-food',
  },
  {
    title: 'Best Plant-Based Dog Food',
    slug: 'best-plant-based-dog-food',
  },
  {
    title: 'Best Puppy Food for Large Dogs',
    slug: 'best-puppy-food-for-large-dogs',
  },
  {
    title: 'Best Puppy Food for Small Dogs',
    slug: 'best-puppy-food-for-small-dogs',
  },
  {
    title: 'Best Raw Dog Food',
    slug: 'best-raw-dog-food',
  },
  {
    title: 'Best Wet Foods for Dogs',
    slug: 'best-wet-foods-for-dogs',
  },
  {
    title: 'Best Wet Puppy Food',
    slug: 'best-wet-puppy-food',
  }
];
