import React from 'react';
import { CircleButton } from '@/wag-react/components/circle-button';

export const CarouselNavigationButton = (props) => (
  <CircleButton
    bg="wagGreen"
    icon="carat"
    display="block"
    minWidth="40px"
    {...props}
  />
);
