import React from 'react';

import { ThemeProvider } from 'styled-components';
import { Typography } from '../../../../wag-react/components/Typography';
import { Box } from '../../../../wag-react/next-components/box';
import { theme } from '../../../../wag-react/themes/base-theme';

import { BodyContainer } from '../BodyContainer';

import { AffiliateGuideList } from './components/AffiliateGuideList';
import { dogFoodCategories, dogFoodBrands } from './components/data';

export const AffiliateGuidesSection = () => (
  <ThemeProvider theme={theme}>
    <BodyContainer
      py={5}
      maxWidth={['360px', '688px', '1200px']}
      px={[3, 3, 0]}
      borderTop="1px solid #979797"
    >
      <Typography
        variant="h2"
        color="medGray"
        m={0}
        textAlign={['left', 'left', 'center']}
        width={1}
      >
        Find the best pet food and pet products for your family
      </Typography>

      <Box
        mt={3}
        width={1}
        display="flex"
        flexWrap={['wrap', null, 'nowrap']}
        flexDirection={['column', 'row']}
      >
        <AffiliateGuideList
          data={dogFoodCategories}
          title="Browse by category"
          page="best-products"
          containerProps={{
            mb: [3, 0],
          }}
        />
        <AffiliateGuideList
          data={dogFoodBrands}
          title="Browse by brand"
          page="reviews"
        />
      </Box>
    </BodyContainer>
  </ThemeProvider>
);
