import petResourcesDogHealth from '../assets/petresource-dog-health.jpg';
import petResourcesDogNames from '../assets/petresource-dog-names.jpg';
import petResourcesDogTraining from '../assets/petresource-dog-training.jpg';
import petResourcesDogActivities from '../assets/petresource-dog-activities.jpg';
import petResourcesDogGrooming from '../assets/petresource-dog-grooming.jpg';
import petResourcesDogBehavior from '../assets/petresource-dog-behavior.jpg';
import petResourcesDogBreeds from '../assets/petresource-dog-breeds.jpg';
import petResourcesDogBeaches from '../assets/petresource-dog-beaches.jpg';
import petResourcesDogSenses from '../assets/petresource-dog-senses.jpg';
import petResourcesDogCampgrounds from '../assets/petresource-dog-campgrounds.jpg';
import petResourcesDogParks from '../assets/petresource-dog-parks.jpg';
import petResourcesDogTrails from '../assets/petresource-dog-trails.jpg';
import { Routes } from '../../../../../constants';

export const petResourcesDataDesktop = [
  [
    {
      url: Routes.KnowledgeCareGuides,
      image: petResourcesDogHealth,
      title: 'Pet Health',
    },
    {
      url: Routes.FunNameGuides,
      image: petResourcesDogNames,
      title: 'Dog Names',
    },
  ],
  [
    {
      url: Routes.TrainingGuides,
      image: petResourcesDogTraining,
      title: 'Dog Training',
    },
    {
      url: Routes.FunDogActivitiesGuides,
      image: petResourcesDogActivities,
      title: 'Dog Activities',
    },
  ],
  [
    {
      url: Routes.KnowlegeGroomingGuides,
      image: petResourcesDogGrooming,
      title: 'Dog Grooming',
    },
    {
      url: Routes.KnowledgeBehaviorGuides,
      image: petResourcesDogBehavior,
      title: 'Dog Behavior',
    },
  ],
  [
    {
      url: Routes.KnowledgeBreedGuides,
      image: petResourcesDogBreeds,
      title: 'Dog Breeds',
    },
    {
      url: Routes.LifestyleBeachesGuides,
      image: petResourcesDogBeaches,
      title: 'Dog Beaches',
    },
  ],
  [
    {
      url: Routes.KnowledgeSenseGuides,
      image: petResourcesDogSenses,
      title: 'Dog Senses',
    },
    {
      url: Routes.LifestyleCampgroundsGuides,
      image: petResourcesDogCampgrounds,
      title: 'Dog Campgrounds',
    },
  ],
  [
    {
      url: Routes.LifestyleParksGuides,
      image: petResourcesDogParks,
      title: 'Dog Parks',
    },
    {
      url: Routes.LifestyleTrailsGuides,
      image: petResourcesDogTrails,
      title: 'Dog Trails',
    },
  ],
];

export const petResourcesDataMobile = [
  {
    url: Routes.KnowledgeCareGuides,
    image: petResourcesDogHealth,
    title: 'Pet Health',
  },
  {
    url: Routes.FunNameGuides,
    image: petResourcesDogNames,
    title: 'Dog Names',
  },
  {
    url: Routes.TrainingGuides,
    image: petResourcesDogTraining,
    title: 'Dog Training',
  },
  {
    url: Routes.FunDogActivitiesGuides,
    image: petResourcesDogActivities,
    title: 'Dog Activities',
  },
  {
    url: Routes.KnowlegeGroomingGuides,
    image: petResourcesDogGrooming,
    title: 'Dog Grooming',
  },
  {
    url: Routes.KnowledgeBehaviorGuides,
    image: petResourcesDogBehavior,
    title: 'Dog Behavior',
  },
  {
    url: Routes.KnowledgeBreedGuides,
    image: petResourcesDogBreeds,
    title: 'Dog Breeds',
  },
  {
    url: Routes.LifestyleBeachesGuides,
    image: petResourcesDogBeaches,
    title: 'Dog Beaches',
  },
  {
    url: Routes.KnowledgeSenseGuides,
    image: petResourcesDogSenses,
    title: 'Dog Senses',
  },
  {
    url: Routes.LifestyleCampgroundsGuides,
    image: petResourcesDogCampgrounds,
    title: 'Dog Campgrounds',
  },
  {
    url: Routes.LifestyleParksGuides,
    image: petResourcesDogParks,
    title: 'Dog Parks',
  },
  {
    url: Routes.LifestyleTrailsGuides,
    image: petResourcesDogTrails,
    title: 'Dog Trails',
  },
];