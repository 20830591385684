export enum SignupFlowSource {
  CityProfile = 'walker-profile-city-page',
  CityPage = 'city-page',
  SittingWeb = 'sitting-web',
  WebPetInsurance= 'pet-insurance-web',
  WebBoarding = 'boarding-web',
  WebDaycare = 'daycare-web',
  WebDropIns = 'drop-ins-web',
  WebHealth = 'health-web',
  WebOvernight = 'overnight-web',
  WebPharmacy = 'pharmacy-web',
  WebPuppyTraining = 'puppy-training-web',
  WebSitting = 'sitting-web',
  WebDigitalTraining = 'digital-training-web',
  WebTraining = 'training-web',
  WebWalking = 'walking-web',
  WalkerProfile = 'walker-profile',
}

export enum UTMContent {
  CityDirectories = 'city-directories',
  ConditionGuide = 'CONDITION_GUIDE',
  DailyWagBlog = 'blog',
  NeighborhoodDirectories = 'neighborhood-directories',
  PetInsuranceServicePage = 'pet insurance service page',
  SymptomGuide = 'SYMPTOM_GUIDE',
  TreatmentGuide = 'TREATMENT_GUIDE',
  WellnessStaticPage = 'WELLNESS',
  Wellness = 'Wellness',
  HomePage = 'home-page',
  WellnessCareArticle = 'care article',
  HeaderBanner = 'header_banner',
}

export enum UTMMedium {
  // A bouncer button
  Bouncer = 'bouncer',
  BookingFlow = 'booking-flow',
  CityBoarding = 'city_boarding',
  CityTraining = 'city_training',
  CityWalking = 'city_walking',
  DailyWag = 'daily_wag',
  Modal = 'modal',
  Hero = 'hero',
  ServiceBoarding = 'service_boarding',
  ServiceCatSitting = 'service_cat-sitting',
  ServiceDigitalTraining = 'service_digital-training',
  ServiceDropIn = 'service_drop-in',
  ServiceHealth = 'service_health',
  ServiceSitting = 'service_sitting',
  ServiceTraining = 'service_training',
  ServiceWalking = 'service_walking',
  WagHealthConnectNowCTA = 'wag-health',
  WagIndex = 'wag_index',
  WalkerProfile = 'walker-profile',
  Wellness = 'wellness',
  Web = 'web',
}

export enum UTMCampaign {
  BookButton = 'book_button',
  ConnectNowButton = 'connect_now_button',
  DeluxeWalkButton = 'deluxe_walk_button',
  DirectBookingButton = 'direct_booking_button',
  ExpressWalkButton = 'express_walk_button',
  FindPetCaregiversButton = 'find_pet_caregivers_button',
  HeroButton = 'hero_button',
  MobileBouncer = 'mobile-bouncer',
  Modal = 'modal',
  PetInsurance = 'pet-insurance',
  ProfileLink = 'profile-link',
  WagWalkButton = 'wag_walk_button',
  WagWellness = 'wag-wellness',
  HomePage = 'home-page',
  WeeklyWalkButton = 'weekly_walk_button',
  ZipCodeSearch = 'zip_code_search',
  HeaderBanner = 'header_banner',
}

export enum UTMTerm {
  CenterOfPage = 'center-of-page',
  CostGraph = 'cost-graph',
  Hero = 'hero',
  InsuranceContentBlock = 'insurance-content-block',
  MobileBouncer = 'mobile-bouncer',
  ContentBanner = 'content-banner',
  Modal = 'modal',
  ModalWidget = 'modal-widget',
  Carousel = 'carousel',
  PetInsuranceSideBarWidget = 'sidebar-widget',
  PetInsuranceQuoteWidget = 'quote-widget',
  VetChatContentBlock = 'vet-chat-content-block',
}

export enum UTMSource {
  Wag = 'wag',
  Web = 'web',
}

// Do not use this outside of query-string; Create new constant on `app.ts`
export enum ServiceWalkType {
  DeluxeWalk = 'deluxe_walk',
  ExpressWalk = 'express_walk',
  WagWalk = 'walk',
  WeeklyWalk = 'weekly_walk',
}
