import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import { BaseTheme } from './base.theme';
import { PetcoTheme } from './petco.theme';

// global css; only add to this if there are no other options
export const GlobalStyle = createGlobalStyle<{
  theme: typeof BaseTheme;
}>`
  ${styledNormalize}

  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    border: 2px solid ${(props) => props.theme.colors.wagGray} !important;
    display: flex!important;

    &__triangle {
      display: none;
    }

    &__month-container {
      border-radius: 8px;
      border: none;
      padding: 0 10px;
      float: none;

      .react-datepicker__header {
        background-color: ${(props) => props.theme.colors.white};
        border: none;

        .react-datepicker__day-names {
          margin: 0 3px;
          border-bottom: 1px solid #D1D1D1;

          .react-datepicker__day-name {
            color: ${(props) => props.theme.colors.medGray};
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            color: ${(props) => props.theme.colors.medGray};
            font-family: ${(props) => props.theme.fonts.body};
            box-sizing: border-box;
          }

          .react-datepicker__day--today {
            background-color: ${(props) => props.theme.colors.wagGreen};
            color: ${(props) => props.theme.colors.white};
            border-radius: 50%;
          }

          .react-datepicker__day--disabled {
            color: ${(props) => props.theme.colors.boneWhite};
          }
        }
      }
    }
  }

  .form-group {
    margin-bottom: 0.5rem!important;
  }

  #__filestack-picker {
    transition: none!important;
  }
`;

// Support existing usages
export default BaseTheme;
export { BaseTheme, PetcoTheme };
