import React from 'react';

import {
  Button as ButtonNext,
  ButtonProps as ButtonNextProps,
} from '../../wag-react/next-components/button';

export type Props = ButtonNextProps;

export type ButtonProps = Props;

export const Button = (props: Props) => (
  <ButtonNext
    {...props}
  />
);
