const getSilhouetteName = ({
  index,
  gender,
}: {
  index: number;
  gender: string;
}) => `silhouette${gender === 'F' ? 'Girl' : 'Guy'}${index}`;

// eslint-disable-next-line max-len
const randomNumber = ({ max, min }: { max: number; min: number }) => Math.floor(Math.random() * (1 + max - min) + min);

export const getRandomSilhouetteName = ({ gender }: { gender: string }) => getSilhouetteName({
  index: randomNumber({
    max: 5,
    min: 1,
  }),
  gender,
});
