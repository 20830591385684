import React from 'react';
import { Box, BoxProps } from '../../wag-react/next-components/box';

export type Props = {
  // TODO: `style` proper type definition
  style?: React.CSSProperties;
} & BoxProps &
  Pick<JSX.IntrinsicElements['div'], 'onClick'>;

export type FlexProps = Props;

export const Flex = (props: Props) => (
  <Box
    display="flex"
    {...props}
  />
);
