export default [
  {
    icon: 'vetted',
    iconAlt: 'vetted-icon',
    headingText: 'Vetted Pet Caregivers',
    text: "Every Pet Caregiver on Wag! must pass an extensive background check so no matter who you choose, you're always getting the pick of the litter.",
  },
  {
    icon: 'insured',
    iconAlt: 'insured-icon',
    headingText: 'Peace of Mind',
    text: "GPS tracking, lock boxes, and live chat with your Pet Caregiver are just some of the paw-some features to give you peace of mind during your pet's service.",
  },
  {
    icon: 'support',
    iconAlt: 'support-icon',
    headingText: 'Support 7 Days a Week',
    text: 'As part of our commitment to safety, Wag!’s customer support is available 7 days a week and is easily accessible through the Wag! app.',
  },
];
