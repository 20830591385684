import React from 'react';
import { Flex } from '@/wag-react/core-components/flex';
import { Text } from '@/wag-react/core-components/text';
import { Image } from '@/wag-react/core-components/image';

import icons from './icons';
import { TypographyProps } from '../Typography';
import { ContainerProps } from '../Container';
import { IconProps } from '../../wag-react/components/alert/Alert';

export type ResponsiveIconAndTextBlockProps = {
  icon: typeof icons | string;
  iconAlt: string;
  text: string;
  headingText?: string;
  headingTextColor?: string;
  headingTextProps?: TypographyProps;
  iconHeight?: number | string | string[];
  iconProps?: IconProps;
  iconWidth?: number | string | string[];
  textContainerProps?: ContainerProps;
  textProps?: TypographyProps;
}

export const ResponsiveIconAndTextBlock = ({
  headingText='',
  headingTextColor='medGray',
  headingTextProps,
  icon,
  iconAlt,
  iconHeight=['110px', '165px', '174px'],
  iconProps,
  iconWidth=['110px', '165px', '174px'],
  text,
  textContainerProps,
  textProps,
  ...rest
}: ResponsiveIconAndTextBlockProps) => (
  <Flex
    flexDirection="column"
    justifyContent={['flex-start', 'center']}
    alignItems="center"
    boxSizing="border-box"
    px={3}
    width={1}
    mb={3}
    {...rest}
  >
    <Image
      src={icons[icon.toString()] || icon}
      width={iconWidth}
      height={iconHeight}
      mb={[0, 3]}
      alt={iconAlt}
      {...iconProps}
    />

    <Flex
      alignItems="center"
      flexDirection="column"
      mt={3}
      mb={[4, 6]}
      width={1}
      {...textContainerProps}
    >
      {headingText && (
        <Text
          m={0}
          mb={2}
          color={headingTextColor}
          fontFamily="muli"
          fontWeight={5}
          fontSize={['20px', '24px', '30px']}
          textAlign={['center']}
          {...headingTextProps}
        >
          {headingText}
        </Text>
      )}

      <Text
        m={0}
        fontFamily="muli"
        fontSize={['16px', '18px']}
        maxWidth={['100%', '100%', 400, 500]}
        color="medGray"
        textAlign={['center']}
        width={[1]}
        lineHeight={[1, 2, 1]}
        {...textProps}
      >
        {text}
      </Text>
    </Flex>
  </Flex>
);

// TODO: Remove default props and use default paremeters instead.
ResponsiveIconAndTextBlock.defaultProps = {
  headingText: '',
  headingTextColor: 'medGray',
  iconWidth: ['110px', '165px', '174px'],
  iconHeight: ['110px', '165px', '174px'],
};
