import React from 'react';
import { Box, BoxProps } from '../../next-components/box';

type Props = BoxProps;
export type LazyWrapperProps = Props;

export const LazyWrapper = (props: Props) => (
  <Box
    pb="100%"
    position="relative"
    width="100%"
    {...props}
  />
);
