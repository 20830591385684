export enum Time {
  OneMinute = 60000,
  TwoMinutes = 120000,
  ThreeMinutes = 180000,
  ThirtyMinutes = 1800000,

  OneSecond = 1000,
  TwoSeconds = 2000,
  ThreeSeconds = 3000,
  TenSeconds = 10000,

  FifteenSeconds = 15000,
}
