// @flow
import React from 'react';

import { Box } from '../../../../wag-react/next-components/box';
import { H2 } from '../../../../wag-react/core-components/h2';
import { Flex } from '../../../../wag-react/core-components/flex';
import { Text}  from '../../../../wag-react/core-components/text';
import { Button } from '../../../../wag-react/next-components/button';

import { Image } from '../../../../wag-react/next-components/image';

import { WagWebAppUrl } from '../../../../constants';

import reason1IconMobile from './assets/reason-1-mobile.png';
import reason1IconDesktop from './assets/reason-1-desktop.png';
import reason2IconMobile from './assets/reason-2-mobile.png';
import reason2IconDesktop from './assets/reason-2-desktop.png';
import reason3IconMobile from './assets/reason-3-mobile.png';
import reason3IconDesktop from './assets/reason-3-desktop.png';
import reason4IconMobile from './assets/reason-4-mobile.png';
import reason4IconDesktop from './assets/reason-4-desktop.png';
import waveBackground from './assets/wave.svg';
import pawWithLine from './assets/paw-with-line.svg';
import paw from './assets/paw.svg';

const reasons = [
  {
    heading: 'Here when you need us',
    subHeading: 'Whether you\'re looking for daily walks, planning a trip, stuck at work, or just want your best friend to have some company — we offer any day, anytime care.',
    iconURL: [reason1IconMobile, reason1IconMobile, reason1IconDesktop],
    iconAlt: 'reason-1-icon',
  },
  {
    heading: 'Safety is serious business',
    subHeading: 'Your dog\'s safety is our top priority. Every Pet Caregiver passes an enhanced background check, our services are insured, and support is standing by 7 days a week.',
    iconURL: [reason2IconMobile, reason2IconMobile, reason2IconDesktop],
    iconAlt: 'reason-2-icon',
  },
  {
    heading: 'All about convenience',
    subHeading: 'We\'ve thought of everything, so you don\'t have to. From GPS-tracked walks and customized lockboxes, to easy booking on the Wag! app, we make your life easier at every step.',
    iconURL: [reason3IconMobile, reason3IconMobile, reason3IconDesktop],
    iconAlt: 'reason-3-icon',
  },
  {
    heading: 'We\'ve been around the block',
    subHeading: 'Wag! has a trusted record of experience with over 12.1M pet care services across 5,300 cities and counting. More than 400,000 Pet Caregivers nationwide are dog people, and it shows.',
    iconURL: [reason4IconMobile, reason4IconMobile, reason4IconDesktop],
    iconAlt: 'reason-4-icon',
  },
];

const isLastItemFromArray = (index: number, arr: Array<any>) => index === arr.length - 1;

export const ReasonsToWag = () => (
  <>
    <Box
      mx={3}
      mt={['40px', null, '80px']}
    >
      <Box>
        <H2
          fontSize={['24px', '30px', '48px']}
          fontWeight="bold"
          fontFamily="muli"
          color="medGray"
          textAlign="center"
        >
          Why Wag!?
        </H2>
      </Box>
      {reasons.map((reason, index) => (
        <Flex
          key={reason.heading}
          flexDirection={['column', 'row']}
          alignItems="center"
          justifyContent={['unset', 'center']}
          maxHeight={['100%', '140px']}
          mb={[0, 4]}
          ml={[0, 4]}
          mr={[0, 4]}
        >
          <Image
            src={reason.iconURL}
            width={['120px']}
            height={['120px']}
            alt={reason.iconAlt}
          />

          <Box display={['none', 'block']}>
            <Image
              src={isLastItemFromArray(index, reasons) ? paw : pawWithLine}
              width={['34px']}
              height={['166px']}
              mt={isLastItemFromArray(index, reasons) ? 0 : '136px'}
              ml={4}
              mr={4}
            />
          </Box>

          <Flex
            flexDirection={['column']}
            alignItems={['center', 'start']}
            width={[1, '432px']}
            my={[3, 0]}
          >
            <Text
              m={0}
              mb={[0, 3]}
              fontSize={['18px', '24px', '30px']}
              fontWeight="bold"
              fontFamily="muli"
              color="medGray"
              lineHeight={[0, '22px']}
              textAlign={['center', 'unset']}
            >
              {reason.heading}
            </Text>
            <Text
              m={0}
              mt={[1, 0]}
              fontSize={['16px', '18px']}
              fontFamily="muli"
              color="medGray"
              lineHeight={[0, '24px']}
              textAlign={['center', 'unset']}
            >
              {reason.subHeading}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Box>
    <Flex
      justifyContent="center"
    >
      <Button
        href={WagWebAppUrl.SignupFlow}
        variant="contained"
      >
        Get started
      </Button>
    </Flex>
    <Box
      backgroundImage={`url(${waveBackground})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      height={['28px', '64px', '136px']}
      mt={4}
    />
  </>
);
